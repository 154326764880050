// expose as constants to allow combinations to be composed as required

export const nullFilters: any[] = [
    { text: "filter.isNullOperator", operator: "isnull" },
    { text: "filter.isNotNullOperator", operator: "isnotnull" },
];

export const textStartsWithFilter: any[] = [{ text: "filter.startsWithOperator", operator: "startswith" }];
export const textContainsFilter: any[] = [{ text: "filter.containsOperator", operator: "contains" }];

export const dateEqualFilter: any[] = [{ text: "filter.eqOperator", operator: "eq" }];
export const dateAfterOrEqualFilter: any[] = [{ text: "filter.afterOrEqualOperator", operator: "gte" }];
export const dateBeforeOrEqualFilter: any[] = [{ text: "filter.beforeOrEqualOperator", operator: "lte" }];
export const dateFilters: any[] = dateEqualFilter.concat(dateAfterOrEqualFilter).concat(dateBeforeOrEqualFilter);

export const numericEqualFilter: any[] = [{ text: "filter.eqOperator", operator: "eq" }];
export const numericGreaterOrEqualFilter: any[] = [{ text: "filter.gteOperator", operator: "gte" }];
export const numericLessOrEqualFilter: any[] = [{ text: "filter.lteOperator", operator: "lte" }];
export const numericFilters: any[] = numericEqualFilter.concat(numericGreaterOrEqualFilter).concat(numericLessOrEqualFilter);
