// framework
import React from "react";
import { Link } from "react-router-dom";
// common
import * as BadgeHelper from "../badges/BadgeHelper";
import * as GeoscienceAustraliaRouteHelper from "../../geoscienceAustralia/geoscienceAustraliaRouteHelper";
// tab
import { TabsEnum } from "../../geoscienceAustralia/reprocessingsProjects/reprocessingProject/components/views/NavBarView";

// ToDo: TechnicalTask 10063 - Replace Dto with Server Side implementation.
interface ReprocessingProjectLinkDto {
    Identifier: string;
    IsPublic: boolean;
    ReprocessingProjectId: number;
    ReprocessingId: number;
}

interface IReprocessingProjectLinkControllerProps {
    linkDto: ReprocessingProjectLinkDto;
    tab?: TabsEnum;
}

function ReprocessingProjectLinkController(props: IReprocessingProjectLinkControllerProps): React.ReactElement {
    const link = props.linkDto;

    const projectId = link.ReprocessingProjectId;
    const reprocessingId = link.ReprocessingId;

    const title = link.Identifier;
    const isPublic = link.IsPublic;

    const tab = props.tab ?? TabsEnum.Details;

    function renderLink(tab: TabsEnum, projectId: number, reprocessingId: number, title: string): React.ReactElement {
        switch (tab) {
            case TabsEnum.Details:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.reprocessingProjectDetails(reprocessingId, projectId)} className={`form-link`} title={`Click to view Project Details.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.Titles:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.reprocessingProjectTitles(reprocessingId, projectId)} className={`form-link`} title={`"Click to view Project Titles.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.Acquisitions:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.reprocessingProjectAcquisitions(reprocessingId, projectId)} className={`form-link`} title={`Click to view Project Acquisitions.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.DataMonitoring:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.reprocessingProjectDataMonitoring(reprocessingId, projectId)} className={`form-link`} title={`Click to view Project Data Monitoring.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.SurveyData:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.reprocessingProjectSurveyData(reprocessingId, projectId)} className={`form-link`} title={`Click to view Project Survey Data.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.Reports:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.reprocessingProjectReports(reprocessingId, projectId)} className={`form-link`} title={`Click to view Project Reports.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.Attachments:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.reprocessingProjectAttachments(reprocessingId, projectId)} className={`form-link`} title={`Click to view Project Attachments.`}>
                        {title}
                    </Link>
                );
            default:
                throw new Error("Unknown entity type.");
        }
    }

    return (
        <>
            {renderLink(tab, projectId, reprocessingId, title)}
            {!isPublic && <BadgeHelper.NotPublicReprocessingProjectBadge />}
        </>
    );
}

export default ReprocessingProjectLinkController;
