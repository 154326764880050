// common
import { IRouteItem, RouteBuilder } from "../infrastructure/routeItem";
// dashboard
import DashboardController from "./home/dashboard/components/Controller";
// wells/boreholes
import WellListController from "./wellsBoreholes/well/list/components/Controller";
import WellDetailsController from "./wellsBoreholes/well/details/components/Controller";
import WellBoreholesController from "./wellsBoreholes/well/boreholes/components/Controller";
import WellDataMonitoringController from "./wellsBoreholes/well/dataMonitoring/components/Controller";
import WellDataMonitoringDetailsController from "./wellsBoreholes/well/dataMonitoringItem/components/Controller";
import WellReportsController from "./wellsBoreholes/well/reports/components/Controller";
import WellReportAttachmentsController from "./wellsBoreholes/well/attachments/components/Controller";
import WellAuditingController from "./wellsBoreholes/well/auditing/components/Controller";
import BoreholeReportAttachmentsController from "./wellsBoreholes/borehole/attachments/components/Controller";
import BoreholeListController from "./wellsBoreholes/borehole/list/components/Controller";
import BoreholeDetailsController from "./wellsBoreholes/borehole/details/components/Controller";
import BoreholeDataMonitoringController from "./wellsBoreholes/borehole/dataMonitoring/components/Controller";
import BoreholeReportsController from "./wellsBoreholes/borehole/reports/components/Controller";
import RigListController from "./wellsBoreholes/rigList/components/Controller";
import RigDetailsController from "./wellsBoreholes/rigDetails/components/Controller";
import RigHistoryListController from "./wellsBoreholes/rigHistoryList/components/Controller";
// Reports & Data
import ReportListController from "./reportsAndData/report/list/components/Controller";
import ReportCreateController from "./reportsAndData/report/create/components/Controller";
import ReportDetailsController from "./reportsAndData/report/details/components/Controller";
import ReportAuditingController from "./reportsAndData/report/auditing/components/Controller";
import ReportFileAssetsController from "./reportsAndData/report/fileAssets/components/Controller";
import ReportRelatedActivitiesController from "./reportsAndData/report/relatedActivities/components/Controller";
import ReportAttachmentsController from "./reportsAndData/report/attachments/components/Controller";
import AttachmentListController from "./reportsAndData/attachments/list/components/Controller";
import SurveyDataListController from "./reportsAndData/surveyData/list/components/Controller";
import SurveyDataCreateController from "./reportsAndData/surveyData/create/components/Controller";
import SurveyDataDetailsController from "./reportsAndData/surveyData/details/components/Controller";
import SurveyDataFileAssetsController from "./reportsAndData/surveyData/fileAssets/components/Controller";
import SurveyDataRelatedActivitiesController from "./reportsAndData/surveyData/relatedActivities/components/Controller";
import SurveyDataAttachmentsController from "./reportsAndData/surveyData/attachments/components/Controller";
import SurveyDataLinesController from "./reportsAndData/surveyData/lines/components/Controller";
import SurveyDataAuditingController from "./reportsAndData/surveyData/auditing/components/Controller";
// OData
import ODataReportController from "./oData/reports/Controller";
import ODataSurveyDataController from "./oData/surveyData/Controller";
// surveys/acquisitions
import SurveyListController from "./surveysAcquisitions/survey/list/components/Controller";
import SurveyDetailsController from "./surveysAcquisitions/survey/details/components/Controller";
import SurveyAcquisitionsController from "./surveysAcquisitions/survey/acquisitions/components/Controller";
import SurveyTitlesController from "./surveysAcquisitions/survey/titles/components/Controller";
import SurveyDataMonitoringController from "./surveysAcquisitions/survey/dataMonitoring/components/Controller";
import SurveyDataMonitoringDetailsController from "./surveysAcquisitions/survey/dataMonitoringItem/components/Controller";
import SurveyParametersController from "./surveysAcquisitions/survey/parameters/components/Controller";
import SurveyReportsController from "./surveysAcquisitions/survey/reports/components/Controller";
import SurveyReportAttachmentsController from "./surveysAcquisitions/survey/attachments/components/Controller";
import SurveySurveyDataController from "./surveysAcquisitions/survey/surveyData/components/Controller";
import SurveyAuditingController from "./surveysAcquisitions/survey/auditing/components/Controller";
import SurveySeismicLinesController from "./surveysAcquisitions/survey/seismicLines/Controller";
import AcquisitionListController from "./surveysAcquisitions/acquisition/list/components/Controller";
import AcquisitionDetailsController from "./surveysAcquisitions/acquisition/details/components/Controller";
import AcquisitionDataMonitoringController from "./surveysAcquisitions/acquisition/dataMonitoring/components/Controller";
import AcquisitionParametersController from "./surveysAcquisitions/acquisition/parameters/components/Controller";
import AcquisitionReportsController from "./surveysAcquisitions/acquisition/reports/components/Controller";
import AcquisitionReportAttachmentsController from "./surveysAcquisitions/acquisition/attachments/components/Controller";
import AcquisitionSurveyDataController from "./surveysAcquisitions/acquisition/surveyData/components/Controller";
// reprocessings/projects
import ReprocessingListController from "./reprocessingsProjects/reprocessing/list/components/Controller";
import ReprocessingDetailsController from "./reprocessingsProjects/reprocessing/details/components/Controller";
import ReprocessingProjectsController from "./reprocessingsProjects/reprocessing/projects/components/Controller";
import ReprocessingTitlesController from "./reprocessingsProjects/reprocessing/titles/components/Controller";
import ReprocessingReportsController from "./reprocessingsProjects/reprocessing/reports/components/Controller";
import ReprocessingReportAttachmentsController from "./reprocessingsProjects/reprocessing/attachments/components/Controller";
import ReprocessingAcquisitionsController from "./reprocessingsProjects/reprocessing/acquisitions/components/Controller";
import ReprocessingDataMonitoringController from "./reprocessingsProjects/reprocessing/dataMonitoring/components/Controller";
import ReprocessingSurveyDataController from "./reprocessingsProjects/reprocessing/surveyData/components/Controller";
import ReprocessingDataMonitoringDetailsController from "./reprocessingsProjects/reprocessing/dataMonitoringItem/components/Controller";
import ReprocessingAuditingController from "./reprocessingsProjects/reprocessing/auditing/components/Controller";
import ReprocessingProjectListController from "./reprocessingsProjects/reprocessingProject/list/components/Controller";
import ReprocessingProjectDetailsController from "./reprocessingsProjects/reprocessingProject/details/components/Controller";
import ReprocessingProjectTitlesController from "./reprocessingsProjects/reprocessingProject/titles/components/Controller";
import ReprocessingProjectAcquisitionsController from "./reprocessingsProjects/reprocessingProject/acquisitions/components/Controller";
import ReprocessingProjectDataMonitoringController from "./reprocessingsProjects/reprocessingProject/dataMonitoring/components/Controller";
import ReprocessingProjectReportsController from "./reprocessingsProjects/reprocessingProject/reports/components/Controller";
import ReprocessingProjectReportAttachmentsController from "./reprocessingsProjects/reprocessingProject/attachments/components/Controller";
import ReprocessingProjectSurveyDataController from "./reprocessingsProjects/reprocessingProject/surveyData/components/Controller";
// dataSubmissions
import DataSubmissionDetailsController from "./dataSubmissions/dataSubmission/details/components/Controller";
import DataSubmissionDataMonitoringController from "./dataSubmissions/dataSubmission/dataMonitoring/components/Controller";
import DataSubmissionResubmissionsController from "./dataSubmissions/dataSubmission/resubmissions/components/Controller";
import DataSubmissionAuditingController from "./dataSubmissions/dataSubmission/auditing/components/Controller";
import DataSubmissionResubmissionDetailsController from "./dataSubmissions/dataSubmission/resubmissionDetails/components/Controller";
import AuthorisationListController from "./dataSubmissions/authorisation/list/components/Controller";
import AuthorisationDetailsController from "./dataSubmissions/authorisation/details/components/Controller";
import AuthorisationAuditingController from "./dataSubmissions/authorisation/auditing/components/Controller";
// feedback
import FeedbackListController from "./feedback/feedbackList/components/Controller";
import FeedbackDetailsController from "./feedback/feedbackDetails/components/Controller";
// geoscience australia
import GeoscienceAustraliaController from "./userManagement/geoscienceAustraliaDetails/components/GeoscienceAustraliaController";
// user management
import RequestListController from "./userManagement/requestList/components/RequestListController";
import UserListController from "./userManagement/userList/components/UserListController";
import UserDetailsController from "./userManagement/userDetails/components/UserDetailsController";
import RequestDetailsController from "./userManagement/requestDetails/components/RequestDetailsController";
// my account
import RequestAccessController from "./account/requestAccess/components/RequestAccessController";
import MyDetailsController from "./account/myDetails/components/MyDetailsController";

const routeBuilder: RouteBuilder = (isSignedIn: boolean) => {
    let routes = new Array<IRouteItem>();

    // not authenticated? don't register routes
    if (!isSignedIn) return routes;

    // home
    routes.push({ path: "/GeoscienceAustralia/Home/Dashboard", exact: true, element: <DashboardController /> });

    // wells/boreholes
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/WellList", exact: true, element: <WellListController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/WellDetails/:wellId", exact: true, element: <WellDetailsController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/WellBoreholes/:wellId", exact: true, element: <WellBoreholesController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/WellDataMonitoring/:wellId", exact: true, element: <WellDataMonitoringController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/DataMonitoringDetails/:wellId/:dataMonitoringItemId", exact: true, element: <WellDataMonitoringDetailsController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/WellReports/:wellId", exact: true, element: <WellReportsController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/WellReportAttachments/:wellId", exact: true, element: <WellReportAttachmentsController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/WellAuditing/:wellId", exact: true, element: <WellAuditingController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/BoreholeReportAttachments/:wellId/:boreholeId", exact: true, element: <BoreholeReportAttachmentsController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/BoreholeList", exact: true, element: <BoreholeListController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/BoreholeDetails/:wellId/:boreholeId", exact: true, element: <BoreholeDetailsController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/BoreholeDataMonitoring/:wellId/:boreholeId", exact: true, element: <BoreholeDataMonitoringController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/BoreholeReports/:wellId/:boreholeId", exact: true, element: <BoreholeReportsController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/RigList", exact: true, element: <RigListController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/RigDetails/:rigId", exact: true, element: <RigDetailsController /> });
    routes.push({ path: "/GeoscienceAustralia/WellsBoreholes/RigHistoryList/:rigId", exact: true, element: <RigHistoryListController /> });

    // Reports & Attachments
    // TODO Technical Task 9988: Fix up GeoscienceAustralia Routes mess
    routes.push({ path: "/GeoscienceAustralia/ReportsAndData/ReportList", exact: true, element: <ReportListController /> });
    routes.push({ path: "/GeoscienceAustralia/ReportCreate", exact: true, element: <ReportCreateController /> });
    routes.push({ path: "/GeoscienceAustralia/ReportCreate/:relatedActivityType/:relatedActivityId", exact: true, element: <ReportCreateController /> });
    routes.push({ path: "/GeoscienceAustralia/ReportDetails/:reportId", exact: true, element: <ReportDetailsController /> });
    routes.push({ path: "/GeoscienceAustralia/ReportAuditing/:reportId", exact: true, element: <ReportAuditingController /> });
    routes.push({ path: "/GeoscienceAustralia/ReportFileAssets/:reportId", exact: true, element: <ReportFileAssetsController /> });
    routes.push({ path: "/GeoscienceAustralia/ReportRelatedActivities/:reportId", exact: true, element: <ReportRelatedActivitiesController /> });
    routes.push({ path: "/GeoscienceAustralia/ReportAttachments/:reportId", exact: true, element: <ReportAttachmentsController /> });
    routes.push({ path: "/GeoscienceAustralia/ReportsAndData/AttachmentList", exact: true, element: <AttachmentListController /> });

    // OData
    routes.push({ path: "/GeoscienceAustralia/OData/Reports", exact: true, element: <ODataReportController /> });
    routes.push({ path: "/GeoscienceAustralia/OData/SurveyData", exact: true, element: <ODataSurveyDataController /> });

    // Survey Data
    // TODO Technical Task 9988: Fix up GeoscienceAustralia Routes mess
    routes.push({ path: "/GeoscienceAustralia/ReportsAndData/SurveyDataList", exact: true, element: <SurveyDataListController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveyDataCreate", exact: true, element: <SurveyDataCreateController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveyDataCreate/:relatedActivityType/:relatedActivityId", exact: true, element: <SurveyDataCreateController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveyDataDetails/:surveyDataId", exact: true, element: <SurveyDataDetailsController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveyDataFileAssets/:surveyDataId", exact: true, element: <SurveyDataFileAssetsController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveyDataRelatedActivities/:surveyDataId", exact: true, element: <SurveyDataRelatedActivitiesController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveyDataAttachments/:surveyDataId", exact: true, element: <SurveyDataAttachmentsController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveyDataLines/:surveyDataId", exact: true, element: <SurveyDataLinesController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveyDataAuditing/:surveyDataId", exact: true, element: <SurveyDataAuditingController /> });

    // surveys/acquisitions
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/SurveyList", exact: true, element: <SurveyListController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/SurveyDetails/:surveyId", exact: true, element: <SurveyDetailsController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/SurveyAcquisitions/:surveyId", exact: true, element: <SurveyAcquisitionsController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/SurveyTitles/:surveyId", exact: true, element: <SurveyTitlesController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/SurveyDataMonitoring/:surveyId", exact: true, element: <SurveyDataMonitoringController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/DataMonitoringDetails/:surveyId/:dataMonitoringItemId", exact: true, element: <SurveyDataMonitoringDetailsController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/SurveyParameters/:surveyId", exact: true, element: <SurveyParametersController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/SurveyReports/:surveyId", exact: true, element: <SurveyReportsController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/SurveyReportAttachments/:surveyId", exact: true, element: <SurveyReportAttachmentsController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/SurveySurveyData/:surveyId", exact: true, element: <SurveySurveyDataController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/SurveySeismicLines/:surveyId", exact: true, element: <SurveySeismicLinesController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/SurveyAuditing/:surveyId", exact: true, element: <SurveyAuditingController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/AcquisitionList", exact: true, element: <AcquisitionListController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/AcquisitionDetails/:surveyId/:acquisitionId", exact: true, element: <AcquisitionDetailsController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/AcquisitionDataMonitoring/:surveyId/:acquisitionId", exact: true, element: <AcquisitionDataMonitoringController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/AcquisitionParameters/:surveyId/:acquisitionId", exact: true, element: <AcquisitionParametersController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/AcquisitionReports/:surveyId/:acquisitionId", exact: true, element: <AcquisitionReportsController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/AcquisitionReportAttachments/:surveyId/:acquisitionId", exact: true, element: <AcquisitionReportAttachmentsController /> });
    routes.push({ path: "/GeoscienceAustralia/SurveysAcquisitions/AcquisitionSurveyData/:surveyId/:acquisitionId", exact: true, element: <AcquisitionSurveyDataController /> });

    // reprocessings/projects
    routes.push({ path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingList", exact: true, element: <ReprocessingListController /> });
    routes.push({ path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingDetails/:reprocessingId", exact: true, element: <ReprocessingDetailsController /> });
    routes.push({ path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjects/:reprocessingId", exact: true, element: <ReprocessingProjectsController /> });
    routes.push({ path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingTitles/:reprocessingId", exact: true, element: <ReprocessingTitlesController /> });
    routes.push({ path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingAcquisitions/:reprocessingId", exact: true, element: <ReprocessingAcquisitionsController /> });
    routes.push({ path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingDataMonitoring/:reprocessingId", exact: true, element: <ReprocessingDataMonitoringController /> });
    routes.push({ path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingSurveyData/:reprocessingId", exact: true, element: <ReprocessingSurveyDataController /> });
    routes.push({ path: "/GeoscienceAustralia/ReprocessingsProjects/ProjectSurveyData/:reprocessingId/:reprocessingProjectId", exact: true, element: <ReprocessingProjectSurveyDataController /> });
    routes.push({
        path: "/GeoscienceAustralia/ReprocessingsProjects/DataMonitoringDetails/:reprocessingId/:dataMonitoringItemId",
        exact: true,
        element: <ReprocessingDataMonitoringDetailsController />,
    });
    routes.push({ path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingAuditing/:reprocessingId", exact: true, element: <ReprocessingAuditingController /> });
    routes.push({ path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingReports/:reprocessingId", exact: true, element: <ReprocessingReportsController /> });
    routes.push({
        path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingAttachments/:reprocessingId",
        exact: true,
        element: <ReprocessingReportAttachmentsController />,
    });
    routes.push({ path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectList", exact: true, element: <ReprocessingProjectListController /> });
    routes.push({
        path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectDetails/:reprocessingId/:reprocessingProjectId",
        exact: true,
        element: <ReprocessingProjectDetailsController />,
    });
    routes.push({
        path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectTitles/:reprocessingId/:reprocessingProjectId",
        exact: true,
        element: <ReprocessingProjectTitlesController />,
    });
    routes.push({
        path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectAcquisitions/:reprocessingId/:reprocessingProjectId",
        exact: true,
        element: <ReprocessingProjectAcquisitionsController />,
    });
    routes.push({
        path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectDataMonitoring/:reprocessingId/:reprocessingProjectId",
        exact: true,
        element: <ReprocessingProjectDataMonitoringController />,
    });
    routes.push({
        path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectReports/:reprocessingId/:reprocessingProjectId",
        exact: true,
        element: <ReprocessingProjectReportsController />,
    });
    routes.push({
        path: "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectAttachments/:reprocessingId/:reprocessingProjectId",
        exact: true,
        element: <ReprocessingProjectReportAttachmentsController />,
    });

    // dataSubmissions
    routes.push({ path: "/GeoscienceAustralia/DataSubmissions/DataSubmissionDetails/:dataSubmissionId", exact: true, element: <DataSubmissionDetailsController /> });
    routes.push({ path: "/GeoscienceAustralia/DataSubmissions/DataSubmissionDataMonitoring/:dataSubmissionId", exact: true, element: <DataSubmissionDataMonitoringController /> });
    routes.push({ path: "/GeoscienceAustralia/DataSubmissions/DataSubmissionResubmissions/:dataSubmissionId", exact: true, element: <DataSubmissionResubmissionsController /> });
    routes.push({ path: "/GeoscienceAustralia/DataSubmissions/DataSubmissionAuditing/:dataSubmissionId", exact: true, element: <DataSubmissionAuditingController /> });
    routes.push({
        path: "/GeoscienceAustralia/DataSubmissions/DataSubmissionResubmissionDetails/:dataSubmissionId/:resubmissionId",
        exact: true,
        element: <DataSubmissionResubmissionDetailsController />,
    });
    routes.push({ path: "/GeoscienceAustralia/DataSubmissions/AuthorisationList/:searchType", exact: true, element: <AuthorisationListController /> });
    routes.push({ path: "/GeoscienceAustralia/DataSubmissions/AuthorisationDetails/:authorisationId", exact: true, element: <AuthorisationDetailsController /> });
    routes.push({ path: "/GeoscienceAustralia/DataSubmissions/AuthorisationAuditing/:authorisationId", exact: true, element: <AuthorisationAuditingController /> });

    // feedback
    routes.push({ path: "/GeoscienceAustralia/Feedback/FeedbackList", exact: true, element: <FeedbackListController /> });
    routes.push({ path: "/GeoscienceAustralia/Feedback/FeedbackDetails/:feedbackId", exact: true, element: <FeedbackDetailsController /> });

    // user management - View My Requests
    routes.push({ path: "/GeoscienceAustralia/User/RequestList", exact: true, element: <RequestListController /> });
    routes.push({ path: "/GeoscienceAustralia/User/RequestDetails/:type/:id", exact: true, element: <RequestDetailsController /> });
    // user management - Geoscience Australia
    routes.push({ path: "/GeoscienceAustralia/User/GeoscienceAustralia", exact: true, element: <GeoscienceAustraliaController /> });
    routes.push({ path: "/GeoscienceAustralia/User/UserList", exact: true, element: <UserListController /> });
    routes.push({ path: "/GeoscienceAustralia/User/UserDetails/:id", exact: true, element: <UserDetailsController /> });

    // my account
    routes.push({ path: "/GeoscienceAustralia/Account/RequestAccess", exact: true, element: <RequestAccessController /> });
    routes.push({ path: "/GeoscienceAustralia/Account/MyDetails", exact: true, element: <MyDetailsController /> });

    return routes;
};

export default routeBuilder;
