// framework
import React from "react";
import { Link } from "react-router-dom";
// common
import * as BadgeHelper from "../badges/BadgeHelper";
import * as GeoscienceAustraliaRouteHelper from "../../geoscienceAustralia/geoscienceAustraliaRouteHelper";
// tab
import { TabsEnum } from "../../geoscienceAustralia/wellsBoreholes/borehole/components/views/NavBarView";

// ToDo: TechnicalTask 10063 - Replace Dto with Server Side implementation.
interface WellBoreholeLinkDto {
    Eno: string;
    IsPublic: boolean;
    WellBoreholeId: number;
    WellId: number;
}

interface IWellBoreholeLinkControllerProps {
    linkDto: WellBoreholeLinkDto;
    tab?: TabsEnum;
}

function WellBoreholeLinkController(props: IWellBoreholeLinkControllerProps): React.ReactElement {
    const link = props.linkDto;

    const boreholeId = link.WellBoreholeId;
    const wellId = link.WellId;

    const title = link.Eno;
    const isPublic = link.IsPublic;

    const tab = props.tab ?? TabsEnum.Details;

    function renderLink(tab: TabsEnum, boreholeId: number, wellId: number, title: string): React.ReactElement {
        switch (tab) {
            case TabsEnum.Details:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.boreholeDetails(wellId, boreholeId)} className={`form-link`} title={`Click to view Borehole Details.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.DataMonitoring:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.boreholeDataMonitoring(wellId, boreholeId)} className={`form-link`} title={`"Click to view Borehole Data Monitoring.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.Reports:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.boreholeReports(wellId, boreholeId)} className={`form-link`} title={`Click to view Borehole Reports.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.Attachments:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.boreholeAttachments(wellId, boreholeId)} className={`form-link`} title={`Click to view Borehole Attachments.`}>
                        {title}
                    </Link>
                );
            default:
                throw new Error("Unknown entity type.");
        }
    }

    return (
        <>
            {renderLink(tab, boreholeId, wellId, title)}
            {!isPublic && <BadgeHelper.NotPublicBoreholeBadge />}
        </>
    );
}

export default WellBoreholeLinkController;
