// framework
import React from "react";
import { Link } from "react-router-dom";
// common
import * as BadgeHelper from "../badges/BadgeHelper";
import * as GeoscienceAustraliaRouteHelper from "../../geoscienceAustralia/geoscienceAustraliaRouteHelper";
// tab
import { TabsEnum } from "../../geoscienceAustralia/surveysAcquisitions/acquisition/components/views/NavBarView";

// ToDo: TechnicalTask 10063 - Replace Dto with Server Side implementation.
interface SurveyAcquisitionLinkDto {
    Identifier: string;
    IsPublic: boolean;
    SurveyAcquisitionId: number;
    SurveyId: number;
}

interface ISurveyAcquisitionLinkControllerProps {
    linkDto: SurveyAcquisitionLinkDto;
    tab?: TabsEnum;
}

function SurveyAcquisitionLinkController(props: ISurveyAcquisitionLinkControllerProps): React.ReactElement {
    const link = props.linkDto;

    const acquisitionId = link.SurveyAcquisitionId;
    const surveyId = link.SurveyId;

    const title = link.Identifier;
    const isPublic = link.IsPublic;

    const tab = props.tab ?? TabsEnum.Details;

    function renderLink(tab: TabsEnum, acquisitionId: number, surveyId: number, title: string): React.ReactElement {
        switch (tab) {
            case TabsEnum.Details:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.acquisitionDetails(surveyId, acquisitionId)} className={`form-link`} title={`Click to view Acquisition Details.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.DataMonitoring:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.acquisitionDataMonitoring(surveyId, acquisitionId)} className={`form-link`} title={`"Click to view Acquisition Data Monitoring.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.SurveyData:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.acquisitionSurveyData(surveyId, acquisitionId)} className={`form-link`} title={`Click to view Acquisition Survey Data.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.Parameters:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.acquisitionParameters(surveyId, acquisitionId)} className={`form-link`} title={`Click to view Acquisition Parameters.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.Reports:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.acquisitionReports(surveyId, acquisitionId)} className={`form-link`} title={`Click to view Acquisition Reports.`}>
                        {title}
                    </Link>
                );
            case TabsEnum.Attachments:
                return (
                    <Link to={GeoscienceAustraliaRouteHelper.acquisitionAttachments(surveyId, acquisitionId)} className={`form-link`} title={`Click to view Acquisition Attachments.`}>
                        {title}
                    </Link>
                );
            default:
                throw new Error("Unknown entity type.");
        }
    }

    return (
        <>
            {renderLink(tab, acquisitionId, surveyId, title)}
            {!isPublic && <BadgeHelper.NotPublicAcquisitionBadge />}
        </>
    );
}

export default SurveyAcquisitionLinkController;
