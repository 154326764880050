import { Dialog } from "@progress/kendo-react-dialogs";
import { ReactElement, useState } from "react";
import { Grid, GridColumnProps } from "@progress/kendo-react-grid";

export interface IListSummaryChild {
    /** The element shown in the grid. */
    displayElement: ReactElement;

    /** The item to show in the popup dialog. */
    item: any;
}

export interface IListSummaryViewProps {
    /** Title used in the details pop up */
    title: string;

    /** The full list of child elements */
    children: IListSummaryChild[];

    /** The grid column definitions */
    gridColumns: React.ReactElement<GridColumnProps>[];

    /** Optional - Sets the delimiter placed between list items when displaying them in the summary and horizontal view. Defaults to ", ". */
    listDelimiter?: string;

    /** Optional - Sets the maximum number of items to be displayed in the summary. Defaults to 5. */
    maxChildrenDisplayCount?: number;
}

export default function ListSummaryGridView(props: IListSummaryViewProps) {
    const { title, children } = props;

    const [showDetailsDialog, setShowDetailsDialog] = useState(false);

    // Default to 5 if it has not been provided.
    const maxChildrenDisplayCount = props.maxChildrenDisplayCount ?? 5;
    // Default to ',' if it has not been provided.
    const listDelimiter = props.listDelimiter ?? ", ";

    // Determine the number of children elements to display
    const truncateChildrenToDisplay = children && children.length > maxChildrenDisplayCount;
    const childrenToDisplay = truncateChildrenToDisplay ? children.slice(0, maxChildrenDisplayCount) : children;

    function handleMoreClick() {
        setShowDetailsDialog(true);
    }

    function onCloseDetailsDialog() {
        setShowDetailsDialog(false);
    }

    return (
        <div className="row">
            <div className="col">
                {childrenToDisplay.map((item: IListSummaryChild, index: number) => (
                    <span key={item.displayElement.key}>
                        {index > 0 && <>{listDelimiter}</>}
                        {item.displayElement}
                    </span>
                ))}
                {truncateChildrenToDisplay && <span>{listDelimiter}…</span>}
            </div>

            <div className="col-auto d-flex align-items-center">
                {children.length > 0 && (
                    <button className="btn btn-outline-secondary btn-sm mx-2" type="button" title={`Click to view ${title}.`} onClick={handleMoreClick}>
                        More ({children.length})
                    </button>
                )}
            </div>

            {showDetailsDialog && (
                <Dialog title={title} width={800} onClose={onCloseDetailsDialog} autoFocus={true}>
                    <div>
                        <Grid id="DialogGrid" style={{ height: "400px" }} sortable={false} pageable={false} resizable navigatable data={children.map((c) => c.item)}>
                            {props.gridColumns}
                        </Grid>
                    </div>
                </Dialog>
            )}
        </div>
    );
}
