// Framework
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
// Shell
import { Level1ItemTypeEnum } from "../../../shell/layout/navigationItemTypes";
// Common
import * as GlobalHelpers from "../../../common/GlobalHelpers";
import { TextBoxFilter } from "../../../common/kendoGrid/GridFilterTextBoxFilter";
import * as GridFilterOptions from "../../../common/kendoGrid/GridFilterOptions";
import CustomGridCell from "../../../common/kendoGrid/CustomGridCell";
import CommentSummaryView from "../../../common/commentSummary/commentSummaryView";
import ODataSearchPageLayout from "../../../common/shell/ODataSearchPageLayout";
import * as GeoscienceAustraliaRouteHelper from "../../geoscienceAustraliaRouteHelper";
import { ICustomFilterExpression } from "../../../common/odata/ODataReader";
import ListSummaryGridView from "../../../common/listSummary/listSummaryGridView";
import SurveyAcquisitionLinkController from "../../../common/links/SurveyAcquisitionLinkController";
import ReprocessingProjectLinkController from "../../../common/links/ReprocessingProjectLinkController";
// Kendo
import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { GridColumn, GridColumnProps } from "@progress/kendo-react-grid";
import { BooleanFilter, DateFilter, FieldSettings, NumericFilter, Operators } from "@progress/kendo-react-data-tools";
// Views
import * as SurveyAcquisitionTabs from "../../surveysAcquisitions/acquisition/components/views/NavBarView";
import * as ReprocessingProjectTabs from "../../reprocessingsProjects/reprocessingProject/components/views/NavBarView";
// Models
import { ISurveyDataDataRow, IActivityDataRow, ISurveyDataLineDataRow } from "./models";

export default function Controller(): React.JSX.Element {
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
            {
                field: "ItemId",
                operator: "startswith",
                value: "",
            },
        ],
    };

    const initalSort: SortDescriptor[] = [
        { field: "LastModifiedOnDate", dir: "desc" },
        { field: "ReceivedDate", dir: "desc" },
    ];

    function generateLink(activity: IActivityDataRow): ReactElement {
        return (
            <span key={activity.Eno}>
                {activity.ActivityType === "Acquisition" && (
                    <SurveyAcquisitionLinkController
                        tab={SurveyAcquisitionTabs.TabsEnum.SurveyData}
                        linkDto={{ Identifier: activity.Eno, IsPublic: activity.IsPublic, SurveyAcquisitionId: activity.SurveyAcquisitionId!, SurveyId: activity.SurveyId! }}
                    />
                )}
                {activity.ActivityType === "Project" && (
                    <ReprocessingProjectLinkController
                        tab={ReprocessingProjectTabs.TabsEnum.SurveyData}
                        linkDto={{ Identifier: activity.Eno, IsPublic: activity.IsPublic, ReprocessingProjectId: activity.ReprocessingProjectId!, ReprocessingId: activity.ReprocessingId! }}
                    />
                )}
            </span>
        );
    }

    const activitySummaryGridColumns: React.ReactElement<GridColumnProps>[] = [
        <GridColumn key="ActivityType" field="ActivityType" title="Activity Type" filter="text" width={150} />,
        <GridColumn
            key="Activity"
            field="Activity"
            title="Activity ID"
            filter="text"
            width={200}
            cell={(props) => <CustomGridCell gridCellProps={props}>{generateLink(props.dataItem)}</CustomGridCell>}
        />,
        <GridColumn key="Name" field="Name" title="Activity Name" filter="text" width={350} />,
    ];

    const gridColumns: React.ReactElement<GridColumnProps>[] = [
        <GridColumn
            key="ItemId"
            field="ItemId"
            title="Item ID"
            width={140}
            cell={(props) => (
                <CustomGridCell gridCellProps={props}>
                    <Link to={GeoscienceAustraliaRouteHelper.surveyDataDetails(props.dataItem.Id)} title="Click to view Survey Data Details.">
                        {props.dataItem.ItemId}
                    </Link>
                </CustomGridCell>
            )}
            filter="text"
        />,
        <GridColumn
            key="Activities"
            title="Related Activities"
            sortable={false}
            width={340}
            cell={(gridCellProps) => (
                <CustomGridCell gridCellProps={gridCellProps}>
                    {gridCellProps.dataItem.Activities && gridCellProps.dataItem.Activities.length > 0 && (
                        <ListSummaryGridView
                            title="Related Activities"
                            maxChildrenDisplayCount={2}
                            children={gridCellProps.dataItem.Activities?.map((activity: IActivityDataRow) => {
                                return { displayElement: generateLink(activity), item: activity };
                            })}
                            gridColumns={activitySummaryGridColumns}
                        />
                    )}
                </CustomGridCell>
            )}
        ></GridColumn>,
        <GridColumn
            key="Description"
            field="Description"
            title="Survey Data Description"
            width={340}
            cell={(props) => (
                <CustomGridCell gridCellProps={props} className="k-command-cell">
                    <CommentSummaryView title="Survey Data Description" comment={props.dataItem.Description} maxSummaryLength={100}></CommentSummaryView>
                </CustomGridCell>
            )}
        />,
        <GridColumn key="DataType" field="DataType" title="Data Type" filter="text" width={200} />,
        <GridColumn
            key="IsPublic"
            field="IsPublic"
            title="Public Visibility"
            width={180}
            cell={(gridCellProps) => (
                <CustomGridCell gridCellProps={gridCellProps}>
                    <span>{gridCellProps.dataItem.IsPublic ? "Public" : "Not Public"}</span>
                </CustomGridCell>
            )}
        ></GridColumn>,
        <GridColumn
            key="Confidentiality"
            title="Confidentiality"
            field="IsConfidential"
            width={180}
            cell={(gridCellProps) => (
                <CustomGridCell gridCellProps={gridCellProps}>
                    <span>{gridCellProps.dataItem.IsConfidential ? "Confidential" : "Not Confidential"}</span>
                </CustomGridCell>
            )}
        ></GridColumn>,
        <GridColumn
            key="ConfidentialityRemarks"
            field="ConfidentialityRemarks"
            title="Confidentiality Remarks"
            width={260}
            cell={(props) => (
                <CustomGridCell gridCellProps={props} className="k-command-cell">
                    <CommentSummaryView title="Confidentiality Remarks" comment={props.dataItem.ConfidentialityRemarks} maxSummaryLength={20}></CommentSummaryView>
                </CustomGridCell>
            )}
        />,
        <GridColumn key="TapeId" field="TapeId" title="Tape Id" filter="text" width={150} />,
        <GridColumn
            key="Consignment"
            field="Consignment"
            title="Consignment"
            width={220}
            cell={(props) => (
                <CustomGridCell gridCellProps={props} className="k-command-cell">
                    <CommentSummaryView title="Consignment" comment={props.dataItem.Consignment} maxSummaryLength={15}></CommentSummaryView>
                </CustomGridCell>
            )}
        />,
        <GridColumn key="DataStatus" field="DataStatus" title="Data Status" filter="text" width={190} />,
        <GridColumn
            key="Remarks"
            field="Remarks"
            title="Remarks"
            width={260}
            cell={(props) => (
                <CustomGridCell gridCellProps={props} className="k-command-cell">
                    <CommentSummaryView title="Remarks" comment={props.dataItem.Remarks} maxSummaryLength={20}></CommentSummaryView>
                </CustomGridCell>
            )}
        />,
        <GridColumn
            key="Lines"
            title="Lines"
            width={280}
            sortable={false}
            cell={(gridCellProps) => (
                <CustomGridCell gridCellProps={gridCellProps}>
                    <ListSummaryGridView
                        title="Lines"
                        maxChildrenDisplayCount={3}
                        children={gridCellProps.dataItem.DataLines.map((line: ISurveyDataLineDataRow) => ({
                            displayElement: <span key={line.LineName}>{line.LineName}</span>,
                            item: line,
                        }))}
                        gridColumns={[
                            <GridColumn key="LineName" field="LineName" title="Line Name" filter="text" width={180} />,
                            <GridColumn key="FSL" field="FSL" title="First Shot Point" filter="text" width={160} />,
                            <GridColumn key="LSL" field="LSL" title="Last Shot Point" filter="text" width={160} />,
                            <GridColumn key="LineComments" field="LineComments" title="Line Comments" filter="text" />,
                        ]}
                    />
                </CustomGridCell>
            )}
        ></GridColumn>,
        <GridColumn key="RDDSStatus" field="RDDSStatus" title="RDDS Status" filter="text" width={150} />,
        <GridColumn
            key="RDDSComments"
            field="RDDSComments"
            title="RDDS Comments"
            width={260}
            cell={(props) => (
                <CustomGridCell gridCellProps={props} className="k-command-cell">
                    <CommentSummaryView title="RDDS Comments" comment={props.dataItem.RDDSComments} maxSummaryLength={20}></CommentSummaryView>
                </CustomGridCell>
            )}
        />,
        <GridColumn
            key="QCRemarks"
            field="QCRemarks"
            title="QC Remarks"
            width={260}
            cell={(props) => (
                <CustomGridCell gridCellProps={props} className="k-command-cell">
                    <CommentSummaryView title="QC Remarks" comment={props.dataItem.QCRemarks} maxSummaryLength={20}></CommentSummaryView>
                </CustomGridCell>
            )}
        />,
        <GridColumn key="DataFormat" field="DataFormat" title="Data Format" filter="text" width={150} />,
        <GridColumn key="MediaType" field="MediaType" title="Media Type" filter="text" width={150} />,
        <GridColumn key="ConditionStatus" field="ConditionStatus" title="Condition Status" filter="text" width={170} />,
        <GridColumn key="CopyStatus" field="CopyStatus" title="Copy Status" filter="text" width={150} />,
        <GridColumn key="LoanStatus" field="LoanStatus" title="Loan Status" filter="text" width={150} />,
        <GridColumn
            key="IsCopiedToNOPIMS"
            field="IsCopiedToNOPIMS"
            title="Is Copied To NOPIMS"
            width={250}
            cell={(gridCellProps) => (
                <CustomGridCell gridCellProps={gridCellProps}>
                    <span>
                        {gridCellProps.dataItem.IsCopiedToNOPIMS === undefined || gridCellProps.dataItem.IsCopiedToNOPIMS === null ? "" : gridCellProps.dataItem.IsCopiedToNOPIMS ? "Yes" : "No"}
                    </span>
                </CustomGridCell>
            )}
        />,
        <GridColumn
            key="TotalAttachments"
            field="TotalAttachments"
            title="Total Attachments"
            filter="numeric"
            width={200}
            cell={(gridCellProps) => (
                <CustomGridCell gridCellProps={gridCellProps}>
                    <Link to={GeoscienceAustraliaRouteHelper.surveyDataAttachments(gridCellProps.dataItem.Id)} title="Click to view Survey Data Attachments.">
                        {gridCellProps.dataItem.TotalAttachments}
                    </Link>
                </CustomGridCell>
            )}
        />,
        <GridColumn key="ReceivedDate" field="ReceivedDate" title="Received Date" filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} width={180} />,
        <GridColumn key="WrittenDate" field="WrittenDate" title="Written Date" filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} width={180} />,
        <GridColumn key="ReleasedDate" field="ReleasedDate" title="Released Date" filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} width={180} />,
        <GridColumn key="ActivityType" field="ActivityType" title="Activity Type" filter="text" sortable={false} width={200} />,
        <GridColumn key="BasicInterpType" field="BasicInterpType" title="Basic Interp Type" filter="text" width={200} />,
        <GridColumn key="BasicRelevantDate" field="BasicRelevantDate" title="Basic Relevant Date" filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />,
        <GridColumn key="InterpRelevantDate" field="InterpRelevantDate" title="Interp Relevant Date" filter="date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />,
        <GridColumn key="Company" field="Company" title="Company" filter="text" width={250} />,
        <GridColumn key="Contractor" field="Contractor" title="Contractor" filter="text" width={250} />,
        <GridColumn key="CreatedBy" field="CreatedBy" title="Created By" filter="text" width={200} />,
        <GridColumn key="CreatedOnDate" field="CreatedOnDate" title="Created On" filter="date" format={GlobalHelpers.NoptaDatetimeFormatForKendo} width={250} />,
        <GridColumn key="LastModifiedBy" field="LastModifiedBy" title="Last Modified By" width={200} />,
        <GridColumn key="LastModifiedOnDate" field="LastModifiedOnDate" title="Last Modified On" filter="date" format={GlobalHelpers.NoptaDatetimeFormatForKendo} width={250} />,
    ];

    const gridFilterFields: FieldSettings[] = [
        { name: "ItemId", label: "Item ID", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        {
            name: "@ActivityName",
            label: "Activity Name",
            filter: TextBoxFilter,
            operators: GridFilterOptions.textStartsWithFilter.concat(GridFilterOptions.textContainsFilter),
        },
        { name: "@ActivityEno", label: "Activity ID", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "Description", label: "Survey Data Description", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "DataType", label: "Data Type", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "IsPublic", label: "Public Visiblity", filter: BooleanFilter, operators: Operators.boolean },
        { name: "IsConfidential", label: "Confidentiality", filter: BooleanFilter, operators: Operators.boolean },
        { name: "ConfidentialityRemarks", label: "Confidentiality Remarks", filter: TextBoxFilter, operators: GridFilterOptions.textContainsFilter },
        { name: "TapeId", label: "Tape Id", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "Consignment", label: "Consignment", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "DataStatus", label: "Data Status", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "Remarks", label: "Remarks", filter: TextBoxFilter, operators: GridFilterOptions.textContainsFilter },
        { name: "@LineName", label: "Line Name", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "RDDSStatus", label: "RDDS Status", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "RDDSComments", label: "RDDS Comments", filter: TextBoxFilter, operators: GridFilterOptions.textContainsFilter },
        { name: "QCRemarks", label: "QC Remarks", filter: TextBoxFilter, operators: GridFilterOptions.textContainsFilter },
        { name: "DataFormat", label: "Data Format", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "MediaType", label: "Media Type", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "ConditionStatus", label: "Condition Status", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "CopyStatus", label: "Copy Status", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "LoanStatus", label: "Loan Status", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "IsCopiedToNOPIMS", label: "Is Copied To NOPIMS", filter: BooleanFilter, operators: Operators.boolean.concat(GridFilterOptions.nullFilters) },
        { name: "TotalAttachments", label: "Total Attachments", filter: NumericFilter, operators: GridFilterOptions.numericFilters },
        { name: "ReceivedDate", label: "Received Date", filter: DateFilter, operators: Operators.date },
        { name: "WrittenDate", label: "Written Date", filter: DateFilter, operators: Operators.date },
        { name: "ReleasedDate", label: "Released Date", filter: DateFilter, operators: Operators.date },
        {
            name: "@ActivityType",
            label: "Activity Type",
            filter: TextBoxFilter,
            operators: GridFilterOptions.textContainsFilter,
        },
        { name: "BasicInterpType", label: "Basic Interp Type", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter.concat(GridFilterOptions.nullFilters) },
        { name: "BasicRelevantDate", label: "Basic Relevant Date", filter: DateFilter, operators: Operators.date },
        { name: "InterpRelevantDate", label: "Interp Relevant Date", filter: DateFilter, operators: Operators.date },
        { name: "Company", label: "Company", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter.concat(GridFilterOptions.nullFilters) },
        { name: "Contractor", label: "Contractor", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter.concat(GridFilterOptions.nullFilters) },
        { name: "CreatedBy", label: "Created By", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "CreatedOnDate", label: "Created On", filter: DateFilter, operators: GridFilterOptions.dateAfterOrEqualFilter.concat(GridFilterOptions.dateBeforeOrEqualFilter) },
        { name: "LastModifiedBy", label: "Last Modified By", filter: TextBoxFilter, operators: GridFilterOptions.textStartsWithFilter },
        { name: "LastModifiedOnDate", label: "Last Modified On", filter: DateFilter, operators: GridFilterOptions.dateAfterOrEqualFilter.concat(GridFilterOptions.dateBeforeOrEqualFilter) },
    ];

    const customFilterExpressions: ICustomFilterExpression[] = [
        {
            name: "@ActivityName",
            expression: (value, operation) => {
                if (!value || value === "") return undefined;
                switch (operation) {
                    case "contains":
                        return `GaSurveyDataActivityODataFeed/any(d:contains(d/Name,'${value}'))`;
                    case "startswith":
                        return `GaSurveyDataActivityODataFeed/any(d:startsWith(d/Name,'${value}'))`;
                    default:
                        throw new Error(`Operation type '${operation}' is not supported!`);
                }
            },
        },
        { name: "@ActivityEno", expression: (value, _) => (value && value !== "" ? `GaSurveyDataActivityODataFeed/any(d:startsWith(d/Eno,'${value}'))` : undefined) },
        { name: "@LineName", expression: (value, _) => (value && value !== "" ? `GaSurveyDataLineODataFeed/any(d:startsWith(d/LineName,'${value}'))` : undefined) },
        {
            name: "@ActivityType",
            expression: (value, operation) => {
                if (!value || value === "") return undefined;
                switch (operation) {
                    case "contains":
                        return `GaSurveyDataActivityODataFeed/any(d:contains(d/ActivityType,'${value}'))`;
                    default:
                        throw new Error(`Operation type '${operation}' is not supported!`);
                }
            },
        },
    ];

    function mapDataRow(dataRow: any): ISurveyDataDataRow {
        return {
            Id: dataRow.Id,
            ItemId: dataRow.ItemId,
            Activities: dataRow.GaSurveyDataActivityOdataFeed.map((a: any) => ({
                ActivityType: a.ActivityType,
                Eno: a.Eno,
                Name: a.Name,
                IsPublic: a.IsPublic,
                SurveyAcquisitionId: a.SurveyAcquisitionId,
                SurveyId: a.SurveyId,
                ReprocessingProjectId: a.ReprocessingProjectId,
                ReprocessingId: a.ReprocessingId,
            })),
            DataLines: dataRow.GaSurveyDataLineOdataFeed.map((dl: any) => ({
                LineName: dl.LineName,
                FSL: dl.Fsl,
                LSL: dl.Lsl,
                LineComments: dl.LineComments,
            })),
            Description: dataRow.Description,
            DataType: dataRow.DataType,
            IsPublic: dataRow.IsPublic,
            IsConfidential: dataRow.IsConfidential,
            ConfidentialityRemarks: dataRow.ConfidentialityRemarks,
            TapeId: dataRow.TapeId,
            Consignment: dataRow.Consignment,
            DataStatus: dataRow.DataStatus,
            Remarks: dataRow.Remarks,
            RDDSStatus: dataRow.Rddsstatus,
            RDDSComments: dataRow.Rddscomments,
            QCRemarks: dataRow.Qcremarks,
            DataFormat: dataRow.DataFormat,
            MediaType: dataRow.MediaType,
            ConditionStatus: dataRow.ConditionStatus,
            CopyStatus: dataRow.CopyStatus,
            LoanStatus: dataRow.LoanStatus,
            IsCopiedToNOPIMS: dataRow.IsCopiedToNopims,
            TotalAttachments: dataRow.TotalAttachments,
            ReceivedDate: dataRow.ReceivedDate ? new Date(dataRow.ReceivedDate) : null,
            WrittenDate: dataRow.WrittenDate ? new Date(dataRow.WrittenDate) : null,
            ReleasedDate: dataRow.ReleasedDate ? new Date(dataRow.ReleasedDate) : null,
            ActivityType: dataRow.GaSurveyDataActivityOdataFeed.length > 0 ? [...new Set(dataRow.GaSurveyDataActivityOdataFeed.map((a: any) => a.ActivityType))].join(", ") : "",
            BasicInterpType: dataRow.BasicInterpType,
            BasicRelevantDate: dataRow.BasicRelevantDate ? new Date(dataRow.BasicRelevantDate) : null,
            InterpRelevantDate: dataRow.InterpRelevantDate ? new Date(dataRow.InterpRelevantDate) : null,
            Company: dataRow.Company,
            Contractor: dataRow.Contractor,
            CreatedBy: dataRow.CreatedBy,
            CreatedOnDate: dataRow.CreatedOnDate ? new Date(dataRow.CreatedOnDate) : null,
            LastModifiedBy: dataRow.LastModifiedBy,
            LastModifiedOnDate: dataRow.LastModifiedOnDate ? new Date(dataRow.LastModifiedOnDate) : null,
        };
    }

    return (
        <ODataSearchPageLayout
            title={["Survey Data OData"]}
            selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_OData_SurveyData}
            oDataUrl={"odata/v1/secure/ga/odatafeed/surveyData/GaSurveyDataODataFeed"}
            oDataQuery={"$expand=GaSurveyDataActivityODataFeed,GaSurveyDataLineODataFeed"}
            gridFilterFields={gridFilterFields}
            customFilterExpressions={customFilterExpressions}
            initialSort={initalSort}
            initialFilter={initialFilter}
            gridColumns={gridColumns}
            mapDataRow={mapDataRow}
        />
    );
}
