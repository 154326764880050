import { Dialog } from "@progress/kendo-react-dialogs";
import { useState } from "react";
import { ControlClass } from "../../common/forms/BootstrapForms";
import bootstrapFormsStyles from "../../common/forms/BootstrapForms.module.css";
import * as StringHelper from "../../common/stringHelper";

export default function CommentSummaryView(props: { comment: string; maxSummaryLength?: number; title: string; truncatePosition?: StringHelper.TruncatePosition }) {
    const { comment, title } = props;

    const [showCommentsDialog, setShowCommentsDialog] = useState(false);

    // default maxSummaryLength to 100 if it has not been provided
    const maxSummaryLength = props.maxSummaryLength ?? 100;

    // default summary start position to be Start
    const truncatePosition = props.truncatePosition ?? StringHelper.TruncatePosition.Start;
    const truncatedComment = !comment ? comment : StringHelper.truncate(comment!, maxSummaryLength, truncatePosition);

    function handleMoreClick() {
        setShowCommentsDialog(true);
    }

    function onCloseCommentsDialog() {
        setShowCommentsDialog(false);
    }
    return (
        <div className="row">
            <div className="col">{truncatedComment}</div>
            {truncatedComment !== comment && (
                <div className="col-auto d-flex align-items-center">
                    <button className="btn btn-outline-secondary btn-sm mx-2" type="button" title={`Click to view ${title}.`} onClick={handleMoreClick}>
                        More
                    </button>
                </div>
            )}
            {showCommentsDialog && (
                <Dialog title={title} width={800} onClose={onCloseCommentsDialog} autoFocus={true}>
                    <div>
                        <span className={`${ControlClass} ${bootstrapFormsStyles.readonlyTextArea}`}>{comment}</span>
                    </div>
                </Dialog>
            )}
        </div>
    );
}
