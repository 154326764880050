// framework
import { clone } from "lodash";
// api
import * as Client from "../../../api/Client";
// navigations
import { Level0ItemTypeEnum, Level1ItemTypeEnum } from "../navigationItemTypes";
// route helpers
import * as homeRouteHelper from "../../../home/homeRouteHelper";
import * as companyRouteHelper from "../../../company/companyRouteHelper";
import * as jointAuthorityRouteHelper from "../../../jointAuthority/jointAuthorityRouteHelper";
import * as geoscienceAustraliaRouteHelper from "../../../geoscienceAustralia/geoscienceAustraliaRouteHelper";
// common
//import * as ConfigurationHelper from "../../../common/shell/configurationHelper"; -- reinstate when feature switching

export interface ILhsNavViewModel {
    visibleItems: Array<ILevel0Item>;
}

export interface ILevel0Item {
    type: Level0ItemTypeEnum;
    text: string;
    count: number | undefined;
    children: Array<ILevel1Item>;
    isVisible: boolean;
}

export interface ILevel1Item {
    type: Level1ItemTypeEnum;
    text: string;
    route: string | undefined;
    count: number | undefined;
    isVisible: boolean;
}

// opting for a centralised registration model for the following reasons:
// 1. no single module owns a L0 menu item
// 2. L1 menu items can be duplicated in specific contexts (i.e. Company/ApplicationsList vs JA/ApplicationsList)
// 3. updates based on security and counts are best done centrally for data consistency and performance

export class LhsNavViewModel implements ILhsNavViewModel {
    constructor(configuration: Client.GetShellLhsNavigationResponseDto | undefined) {
        this._items = new Array<ILevel0Item>();
        this._configuration = configuration;

        this._buildLhsNav();
        this._configure();
        this._recalculate();

        this.visibleItems = this._getVisibleItems();
    }

    visibleItems: Array<ILevel0Item>;

    _items: Array<ILevel0Item>;
    _configuration: Client.GetShellLhsNavigationResponseDto | undefined;

    _buildLhsNav() {
        // builds the menu structure, set up route and countroller associations

        // **************************
        // *** DEVELOPERS EXTEND HERE
        // **************************

        this._items = new Array<ILevel0Item>();

        // common
        this._addL0(Level0ItemTypeEnum.Home, "Home");
        this._addL1(Level0ItemTypeEnum.Home, Level1ItemTypeEnum.Common_Home_SignIn, "Sign In", homeRouteHelper.signIn());
        this._addL1(Level0ItemTypeEnum.Home, Level1ItemTypeEnum.Common_Home_NewUserRequest, "New User Request", homeRouteHelper.registration());

        if (!this._configuration) return;

        // company
        if (this._configuration.secureEntityType === Client.SecureEntityTypeEnum.Company) {
            this._addL1(Level0ItemTypeEnum.Home, Level1ItemTypeEnum.Company_Home_Dashboard, "Dashboard", companyRouteHelper.dashboard());

            // company
            this._addL0(Level0ItemTypeEnum.Opggs, "Petroleum & GHG");
            this._addL1(Level0ItemTypeEnum.Opggs, Level1ItemTypeEnum.Company_Application_DraftApplicationList, "Draft OPGGS Act Applications", companyRouteHelper.draftApplicationList());
            this._addL1(Level0ItemTypeEnum.Opggs, Level1ItemTypeEnum.Company_Application_ApplicationList, "In Progress OPGGS Act Applications", companyRouteHelper.opggsApplicationList());
            this._addL1(Level0ItemTypeEnum.Opggs, Level1ItemTypeEnum.Company_Application_UploadTitleApplication, "Upload OPGGS Act Submissions", companyRouteHelper.applicationAdhocUpload());
            this._addL1(Level0ItemTypeEnum.Opggs, Level1ItemTypeEnum.Company_Opggs_TitlesList, "Titles", companyRouteHelper.titlesList());
            this._addL1(Level0ItemTypeEnum.Opggs, Level1ItemTypeEnum.Company_Opggs_LegislativeForms2To7List, "Legislative Forms (2-7)", companyRouteHelper.legislativeFormsList());

            this._addL0(Level0ItemTypeEnum.Oei, "Offshore Electricity Infrastructure");
            this._addL1(Level0ItemTypeEnum.Oei, Level1ItemTypeEnum.Company_Oei_DraftApplicationList, "Draft OEI Act Applications", companyRouteHelper.oeiDraftApplicationList());
            this._addL1(Level0ItemTypeEnum.Oei, Level1ItemTypeEnum.Company_Oei_ApplicationList, "In Progress OEI Act Applications", companyRouteHelper.oeiApplicationList());
            this._addL1(Level0ItemTypeEnum.Oei, Level1ItemTypeEnum.Company_Oei_InvitationToApplyList, "Invitation To Apply", companyRouteHelper.invitationToApplyList());
            this._addL1(Level0ItemTypeEnum.Oei, Level1ItemTypeEnum.Company_Oei_UploadLicenceApplication, "Upload Licence Application", companyRouteHelper.oeiAdhocUpload());
            this._addL1(Level0ItemTypeEnum.Oei, Level1ItemTypeEnum.Company_Oei_LicenceList, "Licences", companyRouteHelper.licenceList());

            this._addL0(Level0ItemTypeEnum.Finance, "Finance");
            this._addL1(
                Level0ItemTypeEnum.Finance,
                Level1ItemTypeEnum.Company_Finance_FinancialNotificationList,
                "Outstanding Financial Notifications",
                companyRouteHelper.financialNotificationList()
            );
            this._addL1(Level0ItemTypeEnum.Finance, Level1ItemTypeEnum.Company_Finance_PaymentHistoryList, "Payment History", companyRouteHelper.paymentHistoryList());

            this._addL0(Level0ItemTypeEnum.UserManagement, "User Management");
            this._addL1(Level0ItemTypeEnum.UserManagement, Level1ItemTypeEnum.Company_UserManagement_ViewRequestList, "View My Requests", companyRouteHelper.requestList());
            this._addL1(Level0ItemTypeEnum.UserManagement, Level1ItemTypeEnum.Company_UserManagement_ViewUserList, "View My Users", companyRouteHelper.userList());
            this._addL1(Level0ItemTypeEnum.UserManagement, Level1ItemTypeEnum.Company_UserManagement_ViewCompanyList, "View My Companies", companyRouteHelper.companyList());

            this._addL0(Level0ItemTypeEnum.Account, "My Account");
            this._addL1(Level0ItemTypeEnum.Account, Level1ItemTypeEnum.Company_Account_ManageMyDetails, "Manage My Details", companyRouteHelper.myDetails());
            this._addL1(Level0ItemTypeEnum.Account, Level1ItemTypeEnum.Company_Account_RequestAdditionalAccess, "Request Additional Access", companyRouteHelper.requestAccess());
        }

        // joint authority
        else if (this._configuration.secureEntityType === Client.SecureEntityTypeEnum.JointAuthority) {
            this._addL1(Level0ItemTypeEnum.Home, Level1ItemTypeEnum.JointAuthority_Home_Dashboard, "Dashboard", jointAuthorityRouteHelper.dashboard());

            this._addL0(Level0ItemTypeEnum.Opggs, "Petroleum & GHG");
            this._addL1(Level0ItemTypeEnum.Opggs, Level1ItemTypeEnum.JointAuthority_Application_ApplicationList, "In Progress OPGGS Act Applications", jointAuthorityRouteHelper.applicationList());
            this._addL1(Level0ItemTypeEnum.Opggs, Level1ItemTypeEnum.JointAuthority_Opggs_TitlesList, "Titles", jointAuthorityRouteHelper.titlesList());

            this._addL0(Level0ItemTypeEnum.Oei, "Offshore Electricity Infrastructure");
            this._addL1(Level0ItemTypeEnum.Oei, Level1ItemTypeEnum.JointAuthority_Oei_LicenceList, "Licences", jointAuthorityRouteHelper.licenceList());

            this._addL0(Level0ItemTypeEnum.UserManagement, "User Management");
            this._addL1(Level0ItemTypeEnum.UserManagement, Level1ItemTypeEnum.JointAuthority_UserManagement_ViewRequestList, "View My Requests", jointAuthorityRouteHelper.requestList());
            this._addL1(Level0ItemTypeEnum.UserManagement, Level1ItemTypeEnum.JointAuthority_UserManagement_ViewMyUserList, "View My Users", jointAuthorityRouteHelper.myUserList());
            this._addL1(
                Level0ItemTypeEnum.UserManagement,
                Level1ItemTypeEnum.JointAuthority_UserManagement_ViewMyJointAuthority,
                "View My Joint Authority",
                jointAuthorityRouteHelper.myJointAuthority()
            );

            this._addL0(Level0ItemTypeEnum.Account, "My Account");
            this._addL1(Level0ItemTypeEnum.Account, Level1ItemTypeEnum.JointAuthority_Account_ManageMyDetails, "Manage My Details", jointAuthorityRouteHelper.myDetails());
            this._addL1(Level0ItemTypeEnum.Account, Level1ItemTypeEnum.JointAuthority_Account_RequestAdditionalAccess, "Request Additional Access", jointAuthorityRouteHelper.requestAccess());
        }

        // geoscience australia
        else if (this._configuration.secureEntityType === Client.SecureEntityTypeEnum.GeoscienceAustralia) {
            this._addL1(Level0ItemTypeEnum.Home, Level1ItemTypeEnum.GeoscienceAustralia_Home_Dashboard, "Dashboard", geoscienceAustraliaRouteHelper.dashboard());

            this._addL0(Level0ItemTypeEnum.WellsBoreholes, "Wells and Boreholes");
            this._addL1(Level0ItemTypeEnum.WellsBoreholes, Level1ItemTypeEnum.GeoscienceAustralia_WellsBoreholes_Wells, "Wells", geoscienceAustraliaRouteHelper.wellList());
            this._addL1(Level0ItemTypeEnum.WellsBoreholes, Level1ItemTypeEnum.GeoscienceAustralia_WellsBoreholes_Boreholes, "Boreholes", geoscienceAustraliaRouteHelper.boreholeList());
            this._addL1(Level0ItemTypeEnum.WellsBoreholes, Level1ItemTypeEnum.GeoscienceAustralia_WellsBoreholes_Rigs, "Rigs", geoscienceAustraliaRouteHelper.rigList());

            this._addL0(Level0ItemTypeEnum.SurveysAcquisitions, "Surveys and Acquisitions");
            this._addL1(Level0ItemTypeEnum.SurveysAcquisitions, Level1ItemTypeEnum.GeoscienceAustralia_SurveysAcquisitions_Surveys, "Surveys", geoscienceAustraliaRouteHelper.surveyList());
            this._addL1(
                Level0ItemTypeEnum.SurveysAcquisitions,
                Level1ItemTypeEnum.GeoscienceAustralia_SurveysAcquisitions_Acquisitions,
                "Acquisitions",
                geoscienceAustraliaRouteHelper.acquisitionList()
            );

            this._addL0(Level0ItemTypeEnum.ReprocessingsProjects, "Reprocessing and Projects");
            this._addL1(
                Level0ItemTypeEnum.ReprocessingsProjects,
                Level1ItemTypeEnum.GeoscienceAustralia_ReprocessingsProjects_Reprocessings,
                "Reprocessing",
                geoscienceAustraliaRouteHelper.reprocessingList()
            );
            this._addL1(
                Level0ItemTypeEnum.ReprocessingsProjects,
                Level1ItemTypeEnum.GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjects,
                "Projects",
                geoscienceAustraliaRouteHelper.reprocessingProjectList()
            );

            this._addL0(Level0ItemTypeEnum.ReportAndData, "Reports and Survey Data");
            this._addL1(Level0ItemTypeEnum.ReportAndData, Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_Reports, "Reports", geoscienceAustraliaRouteHelper.reportsAndDataReportList());
            this._addL1(
                Level0ItemTypeEnum.ReportAndData,
                Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_Attachments,
                "Attachments",
                geoscienceAustraliaRouteHelper.reportsAndDataAttachmentList()
            );
            this._addL1(
                Level0ItemTypeEnum.ReportAndData,
                Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_SurveyData,
                "Survey Data",
                geoscienceAustraliaRouteHelper.reportsAndDataSurveyDataList()
            );

            this._addL0(Level0ItemTypeEnum.OData, "OData");
            this._addL1(Level0ItemTypeEnum.OData, Level1ItemTypeEnum.GeoscienceAustralia_OData_Reports, "Reports", geoscienceAustraliaRouteHelper.oDataReports());
            this._addL1(Level0ItemTypeEnum.OData, Level1ItemTypeEnum.GeoscienceAustralia_OData_SurveyData, "Survey Data", geoscienceAustraliaRouteHelper.oDataSurveyData());

            this._addL0(Level0ItemTypeEnum.DataSubmissions, "Data Submissions");
            this._addL1(
                Level0ItemTypeEnum.DataSubmissions,
                Level1ItemTypeEnum.GeoscienceAustralia_DataSubmissions_Authorisations,
                "Authorisations",
                geoscienceAustraliaRouteHelper.authorisationList()
            );

            this._addL0(Level0ItemTypeEnum.Feedback, "Feedback Management");
            this._addL1(Level0ItemTypeEnum.Feedback, Level1ItemTypeEnum.GeoscienceAustralia_FeedbackManagement_ViewFeedback, "View Feedback", geoscienceAustraliaRouteHelper.feedbackList());

            this._addL0(Level0ItemTypeEnum.UserManagement, "User Management");
            this._addL1(Level0ItemTypeEnum.UserManagement, Level1ItemTypeEnum.GeoscienceAustralia_UserManagement_ViewRequestList, "View My Requests", geoscienceAustraliaRouteHelper.requestList());
            this._addL1(Level0ItemTypeEnum.UserManagement, Level1ItemTypeEnum.GeoscienceAustralia_UserManagement_ViewMyUserList, "View My Users", geoscienceAustraliaRouteHelper.myUserList());
            this._addL1(
                Level0ItemTypeEnum.UserManagement,
                Level1ItemTypeEnum.GeoscienceAustralia_UserManagement_ManageGeoscienceAustralia,
                "Manage Geoscience Australia",
                geoscienceAustraliaRouteHelper.manageGeoscienceAustralia()
            );

            this._addL0(Level0ItemTypeEnum.Account, "My Account");
            this._addL1(Level0ItemTypeEnum.Account, Level1ItemTypeEnum.GeoscienceAustralia_Account_ManageMyDetails, "Manage My Details", geoscienceAustraliaRouteHelper.myDetails());
            this._addL1(
                Level0ItemTypeEnum.Account,
                Level1ItemTypeEnum.GeoscienceAustralia_Account_RequestAdditionalAccess,
                "Request Additional Access",
                geoscienceAustraliaRouteHelper.requestAccess()
            );
        }

        // nup
        else {
            throw new Error("Unknown Entity Type.");
        }
    }

    _configure() {
        // configures based on the shell configuration provided by the service
        // visibility is based on an opt-in model!  i.e. everything is disabled until proven otherwise

        // **************************
        // *** DEVELOPERS EXTEND HERE
        // **************************

        // not signed-in state, or signed-in but not yet configured
        if (!this._configuration) {
            this._configureItem(Level1ItemTypeEnum.Common_Home_SignIn);
            this._configureItem(Level1ItemTypeEnum.Common_Home_NewUserRequest);
        }

        // company
        else if (this._configuration.secureEntityType === Client.SecureEntityTypeEnum.Company) {
            const config = this._configuration.company;

            // home
            this._configureItem(Level1ItemTypeEnum.Company_Home_Dashboard);
            // application
            this._configureItem(Level1ItemTypeEnum.Company_Application_ApplicationList, config.showApplicationsList);
            this._configureItem(Level1ItemTypeEnum.Company_Application_DraftApplicationList, config.showApplicationsList);
            this._configureItem(Level1ItemTypeEnum.Company_Application_UploadTitleApplication, config.showApplicationAdhocUpload);
            this._configureItem(Level1ItemTypeEnum.Company_Opggs_TitlesList, config.showTitlesList);
            this._configureItem(Level1ItemTypeEnum.Company_Opggs_LegislativeForms2To7List, config.showLegislativeForms2To7List);
            // oei
            this._configureItem(Level1ItemTypeEnum.Company_Oei_ApplicationList, config.showApplicationsList);
            this._configureItem(Level1ItemTypeEnum.Company_Oei_DraftApplicationList, config.showApplicationsList);
            this._configureItem(Level1ItemTypeEnum.Company_Oei_InvitationToApplyList, config.showInvitationToApplyList);
            this._configureItem(Level1ItemTypeEnum.Company_Oei_UploadLicenceApplication, config.showApplicationAdhocUpload);
            this._configureItem(Level1ItemTypeEnum.Company_Oei_LicenceList, config.showLicenceList);
            // finance
            this._configureItem(Level1ItemTypeEnum.Company_Finance_FinancialNotificationList, config.showFinancialNotificationList);
            this._configureItem(Level1ItemTypeEnum.Company_Finance_PaymentHistoryList, config.showPaymentHistoryList);
            // user management
            this._configureItem(Level1ItemTypeEnum.Company_UserManagement_ViewRequestList, config.showViewUserRequests);
            this._configureItem(Level1ItemTypeEnum.Company_UserManagement_ViewUserList, config.showViewUsersList);
            this._configureItem(Level1ItemTypeEnum.Company_UserManagement_ViewCompanyList, config.showViewMyCompanies);
            // my account
            this._configureItem(Level1ItemTypeEnum.Company_Account_ManageMyDetails);
            this._configureItem(Level1ItemTypeEnum.Company_Account_RequestAdditionalAccess);
        }

        // joint authority
        else if (this._configuration.secureEntityType === Client.SecureEntityTypeEnum.JointAuthority) {
            const config = this._configuration.jointAuthority;

            // home
            this._configureItem(Level1ItemTypeEnum.JointAuthority_Home_Dashboard);
            // application
            this._configureItem(Level1ItemTypeEnum.JointAuthority_Application_ApplicationList, config.showApplicationsList);
            this._configureItem(Level1ItemTypeEnum.JointAuthority_Opggs_TitlesList, config.showTitlesList);
            // oei
            this._configureItem(Level1ItemTypeEnum.JointAuthority_Oei_LicenceList, config.showLicencesList);
            // user management
            this._configureItem(Level1ItemTypeEnum.JointAuthority_UserManagement_ViewRequestList, config.showViewUserRequests);
            this._configureItem(Level1ItemTypeEnum.JointAuthority_UserManagement_ViewMyUserList, config.showViewMyUserList);
            this._configureItem(Level1ItemTypeEnum.JointAuthority_UserManagement_ViewMyJointAuthority, config.showViewMyJointAuthority);
            // my account
            this._configureItem(Level1ItemTypeEnum.JointAuthority_Account_RequestAdditionalAccess);
            this._configureItem(Level1ItemTypeEnum.JointAuthority_Account_ManageMyDetails);
        }

        // geoscience australia
        else if (this._configuration.secureEntityType === Client.SecureEntityTypeEnum.GeoscienceAustralia) {
            const config = this._configuration.geoscienceAustralia;

            // home
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_Home_Dashboard);
            // wells/boreholes
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_WellsBoreholes_Wells, config.showViewWellsBoreholesWells);
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_WellsBoreholes_Boreholes, config.showViewWellsBoreholesBoreholes);
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_WellsBoreholes_Rigs, config.showViewWellsBoreholesRigs);
            // surveys/acquisitions
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_SurveysAcquisitions_Surveys, config.showViewSurveysAcquisitionsSurveys);
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_SurveysAcquisitions_Acquisitions, config.showViewSurveysAcquisitionsAcquisitions);
            // reprocessings/projects
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_ReprocessingsProjects_Reprocessings, config.showViewReprocessingsProjectsReprocessings);
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_ReprocessingsProjects_ReprocessingProjects, config.showViewReprocessingsProjectsReprocessingProjects);
            // reports & data
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_Reports, config.showViewReportsAndAttachmentsReports);
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_Attachments, config.showViewReportsAndAttachmentsAttachments);
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_SurveyData, config.showViewReportsAndDataSurveyData);
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_OData_Reports, config.showViewReportsAndDataSurveyData); // TODO Technical Task 9987: Fix up the Geoscience Australia Secure Portal Authorisation mess
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_OData_SurveyData, config.showViewReportsAndDataSurveyData); // TODO Technical Task 9987: Fix up the Geoscience Australia Secure Portal Authorisation mess
            // feedback
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_FeedbackManagement_ViewFeedback, config.showViewFeedbackList);
            // dataSubmissions
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_DataSubmissions_Authorisations, config.showViewDataSubmissionsAuthorisations);
            // user management
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_UserManagement_ViewRequestList, config.showViewUserRequests);
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_UserManagement_ViewMyUserList, config.showViewMyUserList);
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_UserManagement_ManageGeoscienceAustralia, config.showViewGeoscienceAustralia);
            // my account
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_Account_RequestAdditionalAccess);
            this._configureItem(Level1ItemTypeEnum.GeoscienceAustralia_Account_ManageMyDetails);
        }

        // nup
        else {
            throw new Error("Unknown Entity Type.");
        }
    }

    // private implementation

    _addL0(type: Level0ItemTypeEnum, text: string): void {
        const items = this._items.filter((i) => i.type === type);
        if (items.length > 0) throw new Error("Found existing L0 item!");

        this._items.push({
            type: type,
            text: text,
            count: undefined,
            children: new Array<ILevel1Item>(),
            isVisible: false,
        });
    }

    _addL1(parentType: Level0ItemTypeEnum, type: Level1ItemTypeEnum, text: string, route: string | undefined): void {
        const parentItems = this._items.filter((i) => i.type === parentType);
        if (parentItems.length === 0) throw new Error("Cannot find L0 item!");
        if (parentItems.length > 1) throw new Error("Found too many L0 items.");
        const parentItem = parentItems[0];

        parentItem.children.push({
            type: type,
            text: text,
            route: route,
            count: undefined,
            isVisible: false,
        });
    }

    _configureItem(type: Level1ItemTypeEnum, isVisible: boolean = true, count: number | undefined = undefined) {
        this._items.forEach((l0) => {
            const l1Items = l0.children.filter((l1) => l1.type === type);
            if (l1Items.length === 1) {
                const l1 = l1Items[0];
                l1.isVisible = isVisible;
                l1.count = count;
            }
        });
    }

    _recalculate() {
        this._items.forEach((l0) => {
            let isVisible = false;
            let hasCount = false;
            let count = 0;

            l0.children.forEach((l1) => {
                isVisible = isVisible || l1.isVisible;
                hasCount = hasCount || l1.count !== undefined;
                count = count + (l1.count ?? 0);
            });

            l0.count = hasCount ? count : undefined;
            l0.isVisible = isVisible;
        });
    }

    _getVisibleItems(): Array<ILevel0Item> {
        const result = new Array<ILevel0Item>();

        this._items.forEach((l0) => {
            if (l0.isVisible) {
                const r = clone(l0);
                r.children = l0.children.filter((l1) => l1.isVisible);
                result.push(r);
            }
        });

        return result;
    }
}
