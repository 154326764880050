import React, { useEffect, useState } from "react";
import PageLayout from "./PageLayout";
import ODataReader, { ICustomFilterExpression } from "../odata/ODataReader";
import GridFilter from "../kendoGrid/GridFilter";
import { FieldSettings, FilterChangeEvent } from "@progress/kendo-react-data-tools";
import { CompositeFilterDescriptor, DataResult, SortDescriptor, State } from "@progress/kendo-data-query";
import { Grid, GridColumnProps, GridDataStateChangeEvent, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { Level1ItemTypeEnum } from "../../shell/layout/navigationItemTypes";

interface IProps {
    title: (string | undefined)[];
    badges?: React.ReactNode[] | undefined;
    navbar?: React.ReactNode | undefined;
    selectedLhsNavItem?: Level1ItemTypeEnum | undefined;
    oDataUrl: string;
    oDataQuery: string;
    gridFilterFields: Array<FieldSettings>;
    customFilterExpressions?: Array<ICustomFilterExpression> | undefined;
    initialSort?: SortDescriptor[] | undefined;
    initialFilter?: CompositeFilterDescriptor | undefined;
    /** Only supply Grid Columns. We shouldn't include filtering, sorting, etc. Filtering should be done via gridFilterFields.  */
    gridColumns: React.ReactElement<GridColumnProps>[];
    mapDataRow: (dataRow: any) => any;
}

export default function ODataSearchPageLayout(props: IProps): JSX.Element {
    const initialPageSize = 10;
    const pageSizes = [10, 20, 50, 100];
    const initialFilter: CompositeFilterDescriptor = props.initialFilter ?? {
        logic: "and",
        filters: [],
    };

    const [pageSize, setPageSize] = useState(initialPageSize);
    const [gridState, setGridDataState] = useState<State>({
        sort: props.initialSort ?? [],
        take: pageSize,
        skip: 0,
        filter: initialFilter,
    });

    const [filter, setFilter] = useState<CompositeFilterDescriptor>(initialFilter);
    const onFilterChange = (event: FilterChangeEvent) => {
        setFilter(event.filter);
        setGridDataState({ ...gridState, filter: event.filter });
    };

    const [data, setData] = useState<DataResult>({ data: [], total: 0 });
    function onDataChange(data: DataResult): void {
        setData(data);
    }

    useEffect(() => {
        if (data.data.length === 0 && data.total > 0 && gridState && gridState.skip! > 0) setGridDataState({ ...gridState, skip: 0 });
    }, [data, gridState]);

    return (
        <PageLayout title={props.title} badges={props.badges} selectedLhsNavItem={props.selectedLhsNavItem}>
            {props.navbar}
            <div className="row">
                <div className="col">
                    <GridFilter value={filter} onChange={onFilterChange} fields={props.gridFilterFields} />
                </div>
            </div>

            <ODataReader
                url={props.oDataUrl}
                query={props.oDataQuery}
                showContentAsBusyElementId="ODataGrid"
                dataState={gridState}
                onDataReceived={onDataChange}
                mapDataRow={props.mapDataRow}
                customFilterExpressions={props.customFilterExpressions}
                requiresAuth={true}
            />

            <div className="mb-4">
                <Grid
                    id="ODataGrid"
                    sortable
                    pageable={{ pageSizes: pageSizes, pageSizeValue: pageSize, responsive: false }} // Technical Task 9726 - Can remove responsive: false once resolved.
                    onPageChange={(e: GridPageChangeEvent) => {
                        setPageSize(e.page.take);
                        setGridDataState({ ...gridState, take: e.page.take, skip: e.page.skip });
                    }}
                    resizable
                    navigatable
                    {...gridState}
                    data={data}
                    onDataStateChange={(e: GridDataStateChangeEvent) => setGridDataState(e.dataState)}
                >
                    {props.gridColumns}
                </Grid>
            </div>
        </PageLayout>
    );
}
